import React from "react";
import "../style/style.module.scss";
import s from '../style/TyCPage.module.scss';
import { useStaticQuery, graphql } from "gatsby";
import NavBar from '../components/NavBar';
import Experts from '../components/Experts';
import CTAFooter from '../components/CTAFooter';
import Footer from '../components/Footer';
import TycBody from '../components/TycBody';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';


const TyC = () => {
    const tyCs = useStaticQuery(graphql`{
        gcms {
            tyCs  (where: {status: PUBLISHED}){
                title
                parragraph
                body{
                 html
                }
            }
        }
    }`).gcms.tyCs;

    return(
        <div>
            <MetaData/>
            <TagManager/>
            <PageView/>
            <div className={s.headerContainer}>
                <NavBar blue={true}/>
            </div>
            <TycBody tyCs={tyCs} />
            <Experts/>
            <CTAFooter blue={true}>
                Averiguá sin compromiso a qué crédito podrías acceder
            </CTAFooter>
            <Footer/>
            <TagManagerFooter/>
        </div>
    )
}

export default TyC
