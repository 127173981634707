import React from "react";
import s from './TycBody.module.scss';

const TycBody = ({ tyCs }) => {
    return (
        <div className={s.container}>
            <h1 className={s.mainTitle}>{tyCs[0].title}</h1>
            <p className={s.bajada}>{tyCs[0].parragraph}</p>
            <div className={s.text} dangerouslySetInnerHTML={{__html: tyCs[0].body.html}}></div>
        </div>
    )
}

export default TycBody
